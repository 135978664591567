<template>
  <div class="text-center my-4 loader-color">
    <b-spinner class="align-middle mr-1" />
    <strong>{{ i18n().t('dataIsLoading') }}...</strong>
  </div>
</template>

<script>
import i18n from '@/libs/i18n';
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'TableLoader',
  components: {
    BSpinner,
  },
  methods: {
    i18n() {
      return i18n;
    },
  },
};
</script>

<style lang="scss" scoped>
  .loader-color {
    color: #7367f0;
  }
</style>
