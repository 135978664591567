<template>
  <multiselect
    v-model="selectedCountries"
    label="name"
    track-by="id"
    placeholder="Type to search"
    open-direction="bottom"
    :options="countries"
    :multiple="true"
    :searchable="true"
    :clear-on-select="false"
    :close-on-select="false"
    :options-limit="300"
    :limit="3"
    :max-height="600"
    :show-no-results="false"
    :hide-selected="true"
  />
</template>

<script>

export default {
  name: 'CountrySelect',
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      term: '',
    };
  },
  computed: {
    selectedCountries: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    countries() {
      const countries = this.$store.state.app.availableCountries.map(({ id, name, code }) => ({
        id,
        name: `${name} (${code})`,
      }));

      if (!this.term) {
        return countries;
      }

      return countries.filter(({ name }) => name.includes(this.term));
    },
  },
};
</script>
